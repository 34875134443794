import React, { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import Fuse from 'fuse.js';
import '../App.css';

const VehicleSearch = ({ vehicles = { police: [], fire: [], all: [] }, onVehicleSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const map = useMap();
  const wrapperRef = useRef(null);
  const fuseRef = useRef(null);

  // Initialize Fuse with flattened vehicle array
  useEffect(() => {
    const allVehicles = [...(vehicles.police || []), ...(vehicles.fire || [])];
    const fuseOptions = {
      keys: ['displayName'],
      threshold: 0.3,
      distance: 100
    };
    fuseRef.current = new Fuse(allVehicles, fuseOptions);
  }, [vehicles]);

  const searchAddress = async (query) => {
    try {
      // Use the correct endpoint and HTTP method
      const response = await fetch(`/api/address-search?query=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.map(result => ({
        ...result,
        type: 'address'
      }));
    } catch (error) {
      console.error('Error searching address:', error);
      return [];
    }
  };

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    setShowDropdown(true);
    console.log('Search term:', query);

    if (query.length < 2) {
      setResults([]);
      return;
    }

    setIsLoading(true);
    try {
      // Search vehicles
      let vehicleResults = [];
      if (fuseRef.current) {
        vehicleResults = fuseRef.current.search(query)
          .slice(0, 5)
          .map(result => ({
            ...result.item,
            type: 'vehicle'
          }));
      }

      // Search addresses
      const addressResults = await searchAddress(query);

      // Combine results
      setResults([...vehicleResults, ...addressResults]);
    } catch (error) {
      console.error('Error searching:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (result) => {
    console.log('Selected result:', result);
    if (result.type === 'vehicle') {
      onVehicleSelect(result);
    } else if (result.type === 'address') {
      map.setView([result.latitude, result.longitude], 18);
    }
    setShowDropdown(false);
    setSearchTerm('');
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-container" ref={wrapperRef}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search vehicles or addresses..."
        className="search-input"
        autoComplete="off"
      />
      {showDropdown && (results.length > 0 || isLoading) && (
        <div className="search-results">
          {isLoading && <div className="search-loading">Loading...</div>}
          {results.map((result, index) => (
            <div
              key={index}
              className="search-result-item"
              onClick={() => handleSelect(result)}
            >
              <div className="result-icon">
                {result.type === 'vehicle' ? '🚗' : '📍'}
              </div>
              <div className="result-details">
                <div className="result-name">{result.displayName}</div>
                <div className="result-type">
                  {result.type === 'vehicle' ? 'Vehicle' : 'Address'}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VehicleSearch;
