import { useEffect } from 'react';

const VehicleTracker = ({ vehicles }) => {
  useEffect(() => {
    const sendVehicleData = async () => {
      try {
        //console.log('send:', vehicles);
        const response = await fetch('https://devmerlin.westchesterrtc.com/vehicle', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ vehicles })
        });

        if (!response.ok) {
          // Capture detailed server error response for debugging
          const errorText = await response.text();
          throw new Error(`Failed to send vehicle data to server: ${errorText}`);
        } else {
          console.log('Vehicle data sent to server successfully');
        }
      } catch (error) {
        console.error('Error sending vehicle data to server:', error.message);
      }
    };

    const intervalId = setInterval(sendVehicleData, 30000); // Send data every 30 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [vehicles]);

  return null; // No UI to render
};

export default VehicleTracker;
