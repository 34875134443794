import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    MapContainer, TileLayer, CircleMarker, Polygon,
    Polyline, Tooltip, useMap, useMapEvents
} from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-tooltip-layout';
import 'leaflet-draw';
import '../App.css';
import { useLocation } from 'react-router-dom';
import * as turf from '@turf/turf';
import { Modal } from 'react-bootstrap';
import DrawingTools from './DrawingTools';
import StyledIncidentForm from './StyledIncidentForm';
import IncidentModal from './IncidentModal';
import CloseIncidentModal from './CloseIncidentModal';
import EditMarkersModal from './EditMarkersModal';
import VehicleLayer from './VehicleLayer';
import VehicleTracker from './VehicleTracker';
import VehicleSearch from './VehicleSearch';
import LayerControlModal from './LayerControlModal';
import NameColorForm from './NameColorForm';
import logo from '../images/icons/logo.png';
import { PERMISSIONS, hasPermission } from '../utils/permissions';
import StarChaseLayer from './StarChaseLayer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import the marker images
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Import toolbar icons
import homeIcon from '../images/icons/home.png';
import layerIcon from '../images/icons/layer.png';
import plusIcon from '../images/icons/plus-symbol-button.png';
import editIcon from '../images/icons/edit.png';
import closeIcon from '../images/icons/close.png';
import rosterIcon from '../images/icons/people.png';
import adminIcon from '../images/icons/gear.png';

// Fix the default icon paths
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

const MAP_URLS = {
    openStreetMap: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    googleMaps: "https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    googleSatellite: "https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
};

const OverZoomControl = () => {
    const map = useMap();
    const [baseZoom, setBaseZoom] = useState(map.getZoom());
    const [overZoom, setOverZoom] = useState(0);

    useEffect(() => {
        const handleZoomOrPan = () => {
            const currentZoom = map.getZoom();
            const mapContainer = map.getContainer();
            const mapPane = mapContainer.querySelector('.leaflet-map-pane');
            
            if (currentZoom > 18) {
                const scale = Math.pow(2, currentZoom - 18);
                setBaseZoom(18);
                setOverZoom(currentZoom - 18);
                
                if (mapPane) {
                    // Preserve the existing translation transform while adding scale
                    const existingTransform = mapPane.style.transform;
                    const translatePart = existingTransform.match(/translate3d\([^)]+\)/);
                    if (translatePart) {
                        mapPane.style.transform = `${translatePart[0]} scale(${scale})`;
                    }
                }
            } else {
                setBaseZoom(currentZoom);
                setOverZoom(0);
                if (mapPane) {
                    const existingTransform = mapPane.style.transform;
                    const translatePart = existingTransform.match(/translate3d\([^)]+\)/);
                    if (translatePart) {
                        mapPane.style.transform = translatePart[0];
                    }
                }
            }
        };

        const handleZoomStart = () => {
            const mapPane = map.getContainer().querySelector('.leaflet-map-pane');
            if (mapPane) {
                mapPane.style.transition = 'transform 0.2s';
            }
        };

        const handleZoomEnd = () => {
            const mapPane = map.getContainer().querySelector('.leaflet-map-pane');
            if (mapPane) {
                mapPane.style.transition = '';
            }
        };

        // Listen for both zoom and move events
        map.on('zoom', handleZoomOrPan);
        map.on('move', handleZoomOrPan);
        map.on('zoomstart', handleZoomStart);
        map.on('zoomend', handleZoomEnd);

        return () => {
            map.off('zoom', handleZoomOrPan);
            map.off('move', handleZoomOrPan);
            map.off('zoomstart', handleZoomStart);
            map.off('zoomend', handleZoomEnd);
        };
    }, [map]);

    return null;
};

function ZoomToIncident({ incident }) {
    const map = useMap();

    useEffect(() => {
        if (incident) {
            map.setView([incident.location_lat, incident.location_long], 14);
        }
    }, [incident, map]);

    return null;
}

function MapClickHandler({ setIncidentLocation, setMarkerPosition }) {
    const map = useMapEvents({
        click(e) {
            setIncidentLocation(e.latlng);
            setMarkerPosition(e.latlng);
        },
    });

    useEffect(() => {
        const handleLocationFound = (e) => {
            setMarkerPosition(e.latlng);
        };

        map.on('locationfound', handleLocationFound);

        return () => {
            map.off('locationfound', handleLocationFound);
        };
    }, [map, setMarkerPosition]);

    return null;
}

function VehicleSearchHandler({ vehicles, onVehicleSelect }) {
    const map = useMap();

    const handleVehicleSelect = useCallback((vehicle) => {
        const { latitude, longitude } = vehicle;
        map.setView([latitude, longitude], 14);
        onVehicleSelect(vehicle);
    }, [map, onVehicleSelect]);

    return <VehicleSearch vehicles={vehicles} onVehicleSelect={handleVehicleSelect} />;
}

const Toolbar = ({ homePosition, setShowLayerModal, setShowForm, setShowEditMarkersModal, setShowCloseModal, user }) => {
    const map = useMap();
    const navigate = useNavigate(); 

    const handleHomeClick = () => {
        if (map.resetVehicleFollowing) {
            map.resetVehicleFollowing();
        }
        
        map.setView(homePosition, 13);
    };

    const handleRosterClick = (event) => {
        event.preventDefault();
        window.open('/roster', '_blank');
    };

    const handleAdminClick = (event) => {
        event.preventDefault();
        const userData = {
            userId: user.userId,
            userName: user.name,
            userAvatar: user.avatar,
            permissions: user.permissions,
            userEmail: user.email
        };
        const encodedUser = encodeURIComponent(JSON.stringify(userData));
        window.open(`${window.location.origin}/admin?user=${encodedUser}`, '_blank');
    };

    return (
        <div className="overlay-left">
            <button onClick={handleHomeClick} className="toolbar-button" title="Home">
                <img src={homeIcon} alt="Home" />
            </button>
            <button onClick={() => setShowLayerModal(true)} className="toolbar-button" title="Layer Control">
                <img src={layerIcon} alt="Layer Control" />
            </button>
            {setShowForm && (
                <button onClick={() => setShowForm(true)} className="toolbar-button" title="New Incident">
                    <img src={plusIcon} alt="New Incident" />
                </button>
            )}
            <button onClick={() => setShowEditMarkersModal(true)} className="toolbar-button" title="Edit Markers">
                <img src={editIcon} alt="Edit Markers" />
            </button>
            <button onClick={() => setShowCloseModal(true)} className="toolbar-button" title="Close Incident">
                <img src={closeIcon} alt="Close Incident" />
            </button>
            <button onClick={handleRosterClick} className="toolbar-button" title="Roster">
                <img src={rosterIcon} alt="Roster" />
            </button>
            {hasPermission(user, PERMISSIONS.ADMIN) && (
                <button onClick={handleAdminClick} className="toolbar-button" title="Admin Panel">
                    <img src={adminIcon} alt="Admin" />
                </button>
            )}
        </div>
    );
};

const MapPage = ({ setSelectedIncident }) => {
    const position = [41.0340, -73.7629];
    const homePosition = position;
    const [showForm, setShowForm] = useState(false);
    const [incidentLocation, setIncidentLocation] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [incidents, setIncidents] = useState([]);
    const [activeIncidents, setActiveIncidents] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [showEditMarkersModal, setShowEditMarkersModal] = useState(false);
    const [selectedIncident, setSelectedIncidentState] = useState(null); // <-- Define selectedIncident here
    const selectedIncidentRef = useRef(null);
    const [drawnItems, setDrawnItems] = useState(new L.FeatureGroup());
    const [markers, setMarkers] = useState([]);
    const [showNameColorForm, setShowNameColorForm] = useState(false);
    const [currentLayer, setCurrentLayer] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [showLayerModal, setShowLayerModal] = useState(false);
    const [csvFiles, setCsvFiles] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [layerData, setLayerData] = useState({});
    const [user, setUser] = useState({ name: '', avatar: '', permissions: {} });
    const [selectedMapType, setSelectedMapType] = useState('openStreetMap');
    const [showPoliceGPS, setShowPoliceGPS] = useState(() => {
        return hasPermission(user, PERMISSIONS.POLICE_GPS) || hasPermission(user, PERMISSIONS.ADMIN);
    });
    const [showFireGPS, setShowFireGPS] = useState(() => {
        return hasPermission(user, PERMISSIONS.FIRE_GPS) || hasPermission(user, PERMISSIONS.ADMIN);
    });
    const [showStarChase, setShowStarChase] = useState(false);
    const [showTooltips, setShowTooltips] = useState(true);

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const userData = JSON.parse(decodeURIComponent(query.get('user')));

        if (userData) {
            setUser({
                name: userData.userName,
                userId: userData.userId,
                avatar: userData.userAvatar,
                permissions: userData.permissions
            });
        }
    }, [location]);

    useEffect(() => {
        if (user && user.permissions) {
            setShowPoliceGPS(hasPermission(user, PERMISSIONS.POLICE_GPS) || hasPermission(user, PERMISSIONS.ADMIN));
            setShowFireGPS(hasPermission(user, PERMISSIONS.FIRE_GPS) || hasPermission(user, PERMISSIONS.ADMIN));
        }
    }, [user]);

    useEffect(() => {
        const fetchActiveIncidents = async () => {
            try {
                const incidentResponse = await fetch('https://devmerlin.westchesterrtc.com/api/incidents');
                const incidentData = await incidentResponse.json();
                const activeIncidents = incidentData.filter(incident => incident.active);
                setActiveIncidents(activeIncidents);
            } catch (error) {
                console.error('Failed to load active incidents:', error);
            }
        };
        
        // Add cleanup and memory management
        let isMounted = true;
        const controller = new AbortController();
        
        fetchActiveIncidents();
        const intervalId = setInterval(fetchActiveIncidents, 5000);

        return () => {
            isMounted = false;
            controller.abort();
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const fetchMarkers = async () => {
            try {
                if (activeIncidents.length > 0) {
                    const markerResponse = await fetch('https://devmerlin.westchesterrtc.com/api/drawn-items');
                    const markerData = await markerResponse.json();
                    setMarkers(markerData.filter(marker => marker.active));
                } else {
                    setMarkers([]);
                }
            } catch (error) {
                console.error('Failed to load markers:', error);
            }
        };

        fetchMarkers();
    }, [activeIncidents]);

    useEffect(() => {
        const fetchCsvFiles = async () => {
            const response = await fetch('https://devmerlin.westchesterrtc.com/api/csv-files');
            const files = await response.json();
            setCsvFiles(files);
        };

        fetchCsvFiles();
    }, []);

    useEffect(() => {
        const fetchLayerData = async () => {
            const dataPromises = selectedLayers.map(async (filename) => {
                const response = await fetch(`https://devmerlin.westchesterrtc.com/api/csv-data/${filename}`);
                const geoJSONData = await response.json();
                return { filename, data: geoJSONData };
            });

            const layersData = await Promise.all(dataPromises);
            const newLayerData = {};
            layersData.forEach(({ filename, data }) => {
                newLayerData[filename] = data;
            });
            setLayerData(newLayerData);
        };

        fetchLayerData();
    }, [selectedLayers]);

    useEffect(() => {
        const renderMarkersInBatches = () => {
            Object.entries(layerData).forEach(([filename, data]) => {
                let batchSize = 50; // Number of markers to add per batch
                let index = 0;

                const addNextBatch = () => {
                    const nextBatch = data.features.slice(index, index + batchSize);
                    nextBatch.forEach((feature, featureIndex) => {
                        renderGeoJSONLayer(feature, index + featureIndex, 'csv-marker-tooltip');
                    });
                    index += batchSize;

                    if (index < data.features.length) {
                        setTimeout(addNextBatch, 100); // Delay between batches (100ms)
                    }
                };

                addNextBatch();
            });
        };

        renderMarkersInBatches();
    }, [layerData]);

    useEffect(() => {
        selectedIncidentRef.current = selectedIncident;
    }, [selectedIncident]);

    useEffect(() => {
        const toolbar = document.querySelector('.leaflet-draw-toolbar');
        if (toolbar) {
            if (!selectedIncident) {
                toolbar.classList.add('no-selected-incident');
            } else {
                toolbar.classList.remove('no-selected-incident');
            }
        }
    }, [selectedIncident]);

    const handleFormSubmit = async (newIncidentData) => {
        if (!hasPermission(user, PERMISSIONS.MAKE_INCIDENTS)) {
            alert('You do not have permission to create incidents');
            return;
        }

        try {
            const response = await fetch('https://devmerlin.westchesterrtc.com/api/incidents', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newIncidentData)
            });

            if (!response.ok) {
                throw new Error('Failed to create incident');
            }

            const createdIncident = await response.json();

            const newMarker = {
                incident_id: createdIncident.incident_id,
                geojson: {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [createdIncident.location_long, createdIncident.location_lat]
                    },
                    properties: {
                        name: createdIncident.name,
                        color: '#3388ff'
                    }
                }
            };

            const radiusInMeters = createdIncident.radius * 1609.34;
            const circleGeoJSON = {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [Array.from({ length: 64 }, (_, i) => {
                        const angle = (i * 360) / 64;
                        const latitudeOffset = (radiusInMeters / 111320) * Math.cos(angle * (Math.PI / 180));
                        const longitudeOffset = (radiusInMeters / (111320 * Math.cos(createdIncident.location_lat * (Math.PI / 180)))) * Math.sin(angle * (Math.PI / 180));
                        return [createdIncident.location_long + longitudeOffset, createdIncident.location_lat + latitudeOffset];
                    })]
                },
                properties: {
                    name: `${createdIncident.name} `,
                    color: 'rgba(0, 0, 255, 0.2)'
                }
            };

            setMarkers((prevMarkers) => [...prevMarkers, newMarker, { incident_id: createdIncident.incident_id, geojson: circleGeoJSON, name: createdIncident.name, color: 'rgba(0, 0, 255, 0.1)' }]);

            const drawnItemData = {
                incident_id: createdIncident.incident_id,
                geojson: circleGeoJSON,
                name: `${createdIncident.name} Circle`,
                color: 'rgba(0, 0, 255, 0.2)',
                userName: user.name,
                userId: user.userId
            };

            await saveDrawnItem(drawnItemData);

            setSelectedIncidentState(createdIncident); // <-- Update state

            toast.success('Incident entered in database', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setShowForm(false);

        } catch (error) {
            console.error('Error creating incident:', error);
        }
    };

    const handleIncidentClick = (incident) => {
        setSelectedIncidentState(incident); // <-- Update state
        setSelectedIncident(incident); // <-- Call setSelectedIncident prop
    };

    const metersToFeet = (meters) => meters * 3.28084;

    const onCreated = (e) => {
        const { layerType, layer } = e;
        setCurrentLayer(layer);
        setShowNameColorForm(true);
    };

    const handleNameColorSubmit = (data) => {
        setShowNameColorForm(false);
        if (!selectedIncidentRef.current) {
            alert('Please select an active incident first.');
            return;
        }

        const { name, color } = data;

        if (currentLayer) {
            if (currentLayer.setStyle) {
                currentLayer.setStyle({ color });
            }
            currentLayer.bindTooltip(name, { permanent: true, direction: 'top', offset: [0, -20] });
        }

        let geojsonData;
        const layerType = currentLayer.feature ? currentLayer.feature.geometry.type : currentLayer instanceof L.Marker ? 'Point' : '';

        if (currentLayer instanceof L.Circle) {
            const circle = currentLayer;
            const latLng = circle.getLatLng();
            const radius = circle.getRadius();

            if (radius <= 10) {
                geojsonData = {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [latLng.lng, latLng.lat]
                    },
                    properties: {
                        name: name,
                        color: color,
                        radius: radius,
                        markerType: 'CircleMarker'
                    }
                };
            } else {
                const options = { steps: 64, units: 'meters' };
                const turfCircle = turf.circle([latLng.lng, latLng.lat], radius, options);
                turfCircle.properties = {
                    name: name,
                    color: color,
                    radius: radius,
                    markerType: 'ComplexCircle'
                };
                geojsonData = turfCircle;
            }
        } else {
            geojsonData = currentLayer.toGeoJSON();
            geojsonData.properties = { name, color, markerType: layerType };
        }

        const drawnItemData = {
            incident_id: selectedIncidentRef.current.incident_id,
            geojson: geojsonData,
            name: geojsonData.properties.name,
            color: geojsonData.properties.color,
            userName: user.name,
            userId: user.userId  
        };
        console.log('Submitting drawn item data:', drawnItemData); 
        saveDrawnItem(drawnItemData);
    };

    const saveDrawnItem = async (drawnItemData) => {
        try {
            const response = await fetch('https://devmerlin.westchesterrtc.com/api/drawn-items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(drawnItemData)
            });

            if (!response.ok) {
                throw new Error('Failed to save drawn item');
            }

            const responseData = await response.json();
            console.log('Drawn item saved:', responseData);
        } catch (error) {
            console.error('Error saving drawn item:', error);
        }
    };

    const handleDeleteMarkers = async (selectedMarkers) => {
        if (selectedIncidentRef.current && markers.length > 0) {
            try {
                const selectedMarkerIds = new Set(selectedMarkers);

                await Promise.all(
                    markers
                        .filter((m) => m.incident_id === selectedIncidentRef.current.incident_id && selectedMarkerIds.has(m.id))
                        .map((marker) =>
                            fetch(`https://devmerlin.westchesterrtc.com/api/drawn-items/${marker.id}`, {
                                method: 'PATCH',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ active: false }),
                            })
                        )
                );

                const response = await fetch('https://devmerlin.westchesterrtc.com/api/drawn-items');
                const updatedMarkers = await response.json();
                setMarkers(updatedMarkers.filter(marker => marker.active));

                setShowEditMarkersModal(false);
            } catch (error) {
                console.error('Error deactivating markers:', error);
            }
        } else {
            console.warn('No selected incident or markers to delete');
        }
    };

    const handleCloseIncident = async (closeData) => {
        if (!selectedIncidentRef.current) {
            alert('Must select an incident');
            return;
        }

        try {
            const closeResponse = await fetch('https://devmerlin.westchesterrtc.com/api/close-inc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(closeData)
            });

            const closeResult = await closeResponse.json();
            console.log('Close Incident API Response:', closeResult);

            if (!closeResponse.ok) {
                throw new Error('Failed to save close incident data');
            }

            const incidentResponse = await fetch(`https://devmerlin.westchesterrtc.com/api/incidents/${selectedIncidentRef.current.incident_id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ active: false })
            });

            if (!incidentResponse.ok) {
                throw new Error('Failed to close incident');
            }

            const updatedIncident = await incidentResponse.json();

            setActiveIncidents(activeIncidents.filter(incident => incident.incident_id !== selectedIncidentRef.current.incident_id));
            setSelectedIncidentState(null); // <-- Update state

            const updatedMarkers = markers.filter(marker => marker.incident_id !== selectedIncidentRef.current.incident_id);
            setMarkers(updatedMarkers);

            drawnItems.eachLayer((layer) => {
                const layerGeoJSON = layer.toGeoJSON();
                if (layerGeoJSON.properties.incident_id === selectedIncidentRef.current.incident_id) {
                    drawnItems.removeLayer(layer);
                }
            });

            setDrawnItems(new L.FeatureGroup([...drawnItems.getLayers()]));

        } catch (error) {
            console.error('Error closing incident:', error);
        }
    };

    const renderGeoJSONLayer = (geojson, index, customClass = '') => {
        const { type, coordinates } = geojson.geometry;
        const { name, color, radius, markerType } = geojson.properties;
        const uniqueKey = `${name}-${index}`;

        if (!type) {
            console.error('Unsupported GeoJSON type or markerType:', type, markerType);
            return null;
        }

        const tooltipClass = customClass || 'special-tooltip';

        switch (type) {
            case 'Point':
                if (markerType === 'CircleMarker') {
                    return (
                        <CircleMarker
                            key={uniqueKey}
                            center={[coordinates[1], coordinates[0]]}
                            color={color || 'darkblue'}
                            fillColor={color || 'lightblue'}
                            radius={radius || 5}
                            className={customClass}
                        >
                            <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                        </CircleMarker>
                    );
                } else {
                    const latLng = L.latLng(coordinates[1], coordinates[0]);
                    const radiusInMeters = metersToFeet(radius || 5);
                    const turfCircle = turf.circle([latLng.lng, latLng.lat], radiusInMeters, { steps: 64, units: 'meters' });
                    const latlngs = turfCircle.geometry.coordinates[0].map(coord => L.latLng(coord[1], coord[0]));
                    return (
                        <Polygon
                            key={uniqueKey}
                            positions={latlngs}
                            color={color || 'darkblue'}
                            fillColor={color || 'lightblue'}
                            fillOpacity={0.4}
                            className={customClass}
                        >
                            <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                        </Polygon>
                    );
                }
            case 'Polygon':
                return (
                    <Polygon
                        key={uniqueKey}
                        positions={coordinates[0].map(coord => [coord[1], coord[0]])}
                        color={color || 'darkblue'}
                        fillColor={color || 'lightblue'}
                        fillOpacity={0.4}
                        className={customClass}
                    >
                        <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                    </Polygon>
                );
            case 'LineString':
                return (
                    <Polyline
                        key={uniqueKey}
                        positions={coordinates.map(coord => [coord[1], coord[0]])}
                        color={color || 'darkblue'}
                        className={customClass}
                    >
                        <Tooltip permanent className={tooltipClass}>{name}</Tooltip>
                    </Polyline>
                );
            default:
                console.error('Unsupported GeoJSON type or markerType:', type, markerType);
                return null;
        }
    };

    const handleLogout = async () => {
        try {
            // Clear local storage first
            localStorage.removeItem('user');
            localStorage.removeItem('authToken');
            
            // Call server logout endpoint with proper headers
            await fetch('/logout', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Logout request failed:', error);
        } finally {
            // Always redirect to login page, even if the logout request fails
            window.location.href = '/login';
            
            // If the above doesn't work, try forcing a full page reload to /login
            if (window.location.pathname !== '/login') {
                window.location.replace('/login');
            }
        }
    };

    return (
        <>
            <div className="overlay-top">
                <div className="user-info">
                    {user.avatar && <img src={user.avatar} alt="User Avatar" className="user-avatar" />}
                    <span 
                        className="user-name" 
                        onClick={handleLogout}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            userSelect: 'none'
                        }}
                        title="Click to logout"
                    >
                        Welcome, {user.name}
                    </span>
                </div>
            </div>
            <div className={activeIncidents.length === 0 ? 'overlay-right-no-inc' : 'overlay-right'}>
                {activeIncidents.map((incident) => (
                    <IncidentModal
                        key={incident.incident_id}
                        incident={incident}
                        isActive={selectedIncident ? selectedIncident.incident_id === incident.incident_id : false}
                        onClick={handleIncidentClick}
                    />
                ))}
            </div>
            <div className="logo-box">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <MapContainer center={position} zoom={13} maxZoom={21} zoomSnap={0.25} zoomDelta={0.25} wheelDebounceTime={50} wheelPxPerZoomLevel={100} style={{ height: '100vh', width: '100%' }}>
                <TileLayer
                    url={MAP_URLS[selectedMapType]}
                    maxNativeZoom={18}
                    maxZoom={21}
                />
                <OverZoomControl />
                <MapClickHandler setIncidentLocation={setIncidentLocation} setMarkerPosition={setMarkerPosition} />
                <ZoomToIncident incident={selectedIncident} />
                <NameColorForm
                    show={showNameColorForm}
                    onClose={() => setShowNameColorForm(false)}
                    onSubmit={handleNameColorSubmit}
                />
                {incidents.map((incident, incidentIndex) => (
                    <CircleMarker
                        key={`incident-${incident.incident_id}-${incidentIndex}`}
                        center={[incident.location_lat, incident.location_long]}
                        color="darkblue"
                        fillColor="lightblue"
                        radius={10}
                        fillOpacity={0.5}
                    >
                        <Tooltip permanent direction="top" offset={[0, -20]}>{incident.name}</Tooltip>
                    </CircleMarker>
                ))}
                {markerPosition && (
                    <CircleMarker
                        key={`marker-${markerPosition.lat}-${markerPosition.lng}`}
                        center={markerPosition}
                        color="darkblue"
                        fillColor="lightblue"
                        radius={10}
                        fillOpacity={0.5}
                    >
                        <Tooltip permanent direction="top" offset={[0, -20]}>
                            Selected Location: {markerPosition.lat.toFixed(3)}, {markerPosition.lng.toFixed(3)}
                        </Tooltip>
                    </CircleMarker>
                )}
                {markers.map((marker, markerIndex) => {
                    const geojson = marker.geojson;
                    if (geojson && geojson.geometry) {
                        return renderGeoJSONLayer(geojson, markerIndex);
                    } else {
                        console.error('Invalid marker position:', marker);
                        return null;
                    }
                })}
                <DrawingTools onCreated={onCreated} />
                {(showPoliceGPS || showFireGPS) && (
                    <VehicleLayer 
                        setVehicles={setVehicles} 
                        showPoliceGPS={showPoliceGPS}
                        showFireGPS={showFireGPS}
                        showTooltips={showTooltips}
                    />
                )}
                {(showPoliceGPS || showFireGPS) && vehicles.length > 0 && activeIncidents.length > 0 && (
                    <VehicleTracker 
                        vehicles={vehicles.filter(v => 
                            (showPoliceGPS && v.type === 'police') || 
                            (showFireGPS && (v.type === 'fire' || v.type === 'ems'))
                        )} 
                        incidents={activeIncidents} 
                    />
                )}
                <VehicleSearchHandler vehicles={vehicles} onVehicleSelect={() => { }} />
                {Object.entries(layerData).map(([filename, layerDataItem], index) => (
                    <React.Fragment key={index}>
                        {layerDataItem.features.map((feature, featureIndex) => 
                            renderGeoJSONLayer(feature, featureIndex, 'csv-marker')
                    )}
                </React.Fragment>
                ))}
                <Toolbar
                    homePosition={homePosition}
                    setShowLayerModal={setShowLayerModal}
                    setShowForm={hasPermission(user, PERMISSIONS.MAKE_INCIDENTS) ? setShowForm : null}
                    setShowEditMarkersModal={setShowEditMarkersModal}
                    setShowCloseModal={setShowCloseModal}
                    user={user}
                />
                {showStarChase && hasPermission(user, PERMISSIONS.STARCHASE) && (
                    <StarChaseLayer />
                )}
            </MapContainer>

            {showForm && <StyledIncidentForm onClose={() => setShowForm(false)} onSubmit={handleFormSubmit} location={incidentLocation} />}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Body className="modal-success">{successMessage}</Modal.Body>
            </Modal>
            <CloseIncidentModal
                show={showCloseModal}
                onClose={() => setShowCloseModal(false)}
                onSubmit={handleCloseIncident}
                incidentId={selectedIncidentRef.current ? selectedIncidentRef.current.incident_id : null}
            />
            <EditMarkersModal
                show={showEditMarkersModal}
                onClose={() => setShowEditMarkersModal(false)}
                markers={markers.filter(marker => marker.incident_id === (selectedIncidentRef.current ? selectedIncidentRef.current.incident_id : null))}
                onDelete={handleDeleteMarkers}
            />
            <LayerControlModal
                show={showLayerModal}
                onHide={() => setShowLayerModal(false)}
                csvFiles={csvFiles}
                selectedMapType={selectedMapType}
                onMapTypeChange={setSelectedMapType}
                selectedLayers={selectedLayers}
                setSelectedLayers={setSelectedLayers}
                user={user}
                showPoliceGPS={showPoliceGPS}
                setShowPoliceGPS={setShowPoliceGPS}
                showFireGPS={showFireGPS}
                setShowFireGPS={setShowFireGPS}
                showStarChase={showStarChase}
                setShowStarChase={setShowStarChase}
                showTooltips={showTooltips}
                setShowTooltips={setShowTooltips}
            />
        </>
    );
};

export default MapPage;
