import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { PERMISSIONS, hasPermission } from '../utils/permissions';
import './LayerControlModal.css';

const LayerControlModal = ({ 
    show, 
    onHide, 
    selectedMapType, 
    onMapTypeChange,
    showPoliceGPS,
    setShowPoliceGPS,
    showFireGPS,
    setShowFireGPS,
    showStarChase,
    setShowStarChase,
    showTooltips,
    setShowTooltips,
    user,
    csvFiles = [],
    selectedLayers = [],
    setSelectedLayers
}) => {
    const handleLayerToggle = (file) => {
        if (selectedLayers.includes(file)) {
            setSelectedLayers(selectedLayers.filter(layer => layer !== file));
        } else {
            setSelectedLayers([...selectedLayers, file]);
        }
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            className="layer-control-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Layer Control</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Base Map Selection */}
                <div className="control-section">
                    <h5 className="section-title">Base Map</h5>
                    <div className="options-container">
                        {['openStreetMap', 'googleMaps', 'googleSatellite'].map((type) => (
                            <div key={type} className="map-option">
                                <input
                                    type="radio"
                                    className="radio-input"
                                    id={type}
                                    name="mapType"
                                    value={type}
                                    checked={selectedMapType === type}
                                    onChange={(e) => onMapTypeChange(e.target.value)}
                                />
                                <label className="radio-label" htmlFor={type}>
                                    {type === 'openStreetMap' ? 'OpenStreetMap' :
                                     type === 'googleMaps' ? 'Google Maps' : 'Satellite'}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* GPS Controls */}
                {(hasPermission(user, PERMISSIONS.POLICE_GPS) || hasPermission(user, PERMISSIONS.FIRE_GPS) || hasPermission(user, PERMISSIONS.STARCHASE)) && (
                    <div className="control-section">
                        <h5 className="section-title">GPS & Tracking Layers</h5>
                        <div className="options-container">
                            {hasPermission(user, PERMISSIONS.POLICE_GPS) && (
                                <div className="toggle-option">
                                    <input
                                        type="checkbox"
                                        className="toggle-input"
                                        id="policeGPS"
                                        checked={showPoliceGPS}
                                        onChange={(e) => setShowPoliceGPS(e.target.checked)}
                                    />
                                    <label className="toggle-label" htmlFor="policeGPS">
                                        Show Police GPS
                                    </label>
                                </div>
                            )}
                            {hasPermission(user, PERMISSIONS.FIRE_GPS) && (
                                <div className="toggle-option">
                                    <input
                                        type="checkbox"
                                        className="toggle-input"
                                        id="fireGPS"
                                        checked={showFireGPS}
                                        onChange={(e) => setShowFireGPS(e.target.checked)}
                                    />
                                    <label className="toggle-label" htmlFor="fireGPS">
                                        Show Fire/EMS GPS
                                    </label>
                                </div>
                            )}
                            {hasPermission(user, PERMISSIONS.STARCHASE) && (
                                <div className="toggle-option">
                                    <input
                                        type="checkbox"
                                        className="toggle-input"
                                        id="starChase"
                                        checked={showStarChase}
                                        onChange={(e) => setShowStarChase(e.target.checked)}
                                    />
                                    <label className="toggle-label" htmlFor="starChase">
                                        Show StarChase Trackers
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Additional Layers */}
                <div className="control-section">
                    <h5 className="section-title">Additional Layers</h5>
                    <div className="options-container">
                        {csvFiles.length === 0 ? (
                            <p className="no-layers">No additional layers available</p>
                        ) : (
                            csvFiles.map((file, index) => (
                                <div key={`layer-${index}-${file}`} className="toggle-option">
                                    <input
                                        type="checkbox"
                                        className="toggle-input"
                                        id={`layer-${index}-${file}`}
                                        checked={selectedLayers.includes(file)}
                                        onChange={() => handleLayerToggle(file)}
                                    />
                                    <label 
                                        className="toggle-label" 
                                        htmlFor={`layer-${index}-${file}`}
                                    >
                                        {file.replace(/\.[^/.]+$/, '')}
                                    </label>
                                </div>
                            ))
                        )}
                    </div>
                </div>

                {/* Display Options */}
                <div className="control-section">
                    <h5 className="section-title">Display Options</h5>
                    <div className="options-container">
                        <div className="toggle-option">
                            <input
                                type="checkbox"
                                className="toggle-input"
                                id="tooltips"
                                checked={showTooltips}
                                onChange={(e) => setShowTooltips(e.target.checked)}
                            />
                            <label className="toggle-label" htmlFor="tooltips">
                                Show Vehicle Labels
                            </label>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LayerControlModal;
