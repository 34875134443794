import React, { useState, useEffect } from 'react';

const DatabaseManager = () => {
    const [activeTab, setActiveTab] = useState('incidentTypes');
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState('');
    const [editingItem, setEditingItem] = useState(null);
    const [error, setError] = useState(null);

    // Debug logging for table names
    useEffect(() => {
        console.log('Current table type:', activeTab);
    }, [activeTab]);

    const tabs = {
        incidentTypes: 'Incident Types',
        dispositions: 'Dispositions',
        assignments: 'Assignments'
    };

    const fetchItems = async (type) => {
        try {
            const response = await fetch(`/api/admin/database/${type}`);
            const data = await response.json();
            
            // Debug logging
            console.log('Fetched data:', data);
            
            // Ensure we have an array
            if (!Array.isArray(data)) {
                console.error('Data is not an array:', data);
                setItems([]);
                setError('Invalid data format received');
                return;
            }
            
            setItems(data);
            setError(null);
        } catch (error) {
            console.error(`Failed to fetch ${type}:`, error);
            setItems([]);
            setError(`Failed to fetch ${type}`);
        }
    };

    useEffect(() => {
        fetchItems(activeTab);
    }, [activeTab]);

    const handleAdd = async () => {
        if (!newItem.trim()) return;

        try {
            const response = await fetch(`/api/admin/database/${activeTab}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: newItem })
            });

            if (response.ok) {
                setNewItem('');
                fetchItems(activeTab);
            }
        } catch (error) {
            console.error('Failed to add item:', error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                const response = await fetch(`/api/admin/database/${activeTab}/${id}`, {
                    method: 'DELETE'
                });

                if (response.ok) {
                    fetchItems(activeTab);
                }
            } catch (error) {
                console.error('Failed to delete item:', error);
            }
        }
    };

    const handleEdit = async (id) => {
        if (editingItem?.id === id) {
            try {
                const response = await fetch(`/api/admin/database/${activeTab}/${id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ name: editingItem.name })
                });

                if (response.ok) {
                    setEditingItem(null);
                    fetchItems(activeTab);
                }
            } catch (error) {
                console.error('Failed to update item:', error);
            }
        } else {
            const item = items.find(item => item.id === id);
            setEditingItem(item);
        }
    };

    return (
        <div style={styles.container}>
            <h3 style={styles.heading}>Database Manager</h3>
            
            {error && (
                <div style={styles.error}>
                    {error}
                </div>
            )}
            
            <div style={styles.tabs}>
                {Object.entries(tabs).map(([key, label]) => (
                    <button
                        key={key}
                        style={{
                            ...styles.tabButton,
                            ...(activeTab === key ? styles.activeTab : {})
                        }}
                        onClick={() => setActiveTab(key)}
                    >
                        {label}
                    </button>
                ))}
            </div>

            <div style={styles.addSection}>
                <input
                    type="text"
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    placeholder={`Add new ${activeTab.slice(0, -1)}...`}
                    style={styles.input}
                />
                <button onClick={handleAdd} style={styles.addButton}>
                    Add
                </button>
            </div>

            <div style={styles.itemList}>
                {items.map(item => (
                    <div key={item.id} style={styles.item}>
                        {editingItem?.id === item.id ? (
                            <input
                                type="text"
                                value={editingItem.name}
                                onChange={(e) => setEditingItem({...editingItem, name: e.target.value})}
                                style={styles.editInput}
                            />
                        ) : (
                            <span>{item.name}</span>
                        )}
                        <div style={styles.itemButtons}>
                            <button
                                onClick={() => handleEdit(item.id)}
                                style={styles.editButton}
                            >
                                {editingItem?.id === item.id ? 'Save' : 'Edit'}
                            </button>
                            <button
                                onClick={() => handleDelete(item.id)}
                                style={styles.deleteButton}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        marginTop: '20px'
    },
    heading: {
        marginBottom: '20px',
        color: '#fff'
    },
    tabs: {
        display: 'flex',
        gap: '10px',
        marginBottom: '20px'
    },
    tabButton: {
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: '#444',
        color: '#fff'
    },
    activeTab: {
        backgroundColor: '#007bff'
    },
    addSection: {
        display: 'flex',
        gap: '10px',
        marginBottom: '20px'
    },
    input: {
        flex: 1,
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #444',
        backgroundColor: '#333',
        color: '#fff'
    },
    addButton: {
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#28a745',
        color: '#fff',
        cursor: 'pointer'
    },
    itemList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: '#444',
        borderRadius: '4px',
        color: '#fff'
    },
    itemButtons: {
        display: 'flex',
        gap: '10px'
    },
    editButton: {
        padding: '4px 8px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffc107',
        color: '#000',
        cursor: 'pointer'
    },
    deleteButton: {
        padding: '4px 8px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#dc3545',
        color: '#fff',
        cursor: 'pointer'
    },
    editInput: {
        padding: '4px',
        borderRadius: '4px',
        border: '1px solid #666',
        backgroundColor: '#333',
        color: '#fff'
    },
    error: {
        backgroundColor: '#ff000033',
        color: '#ff0000',
        padding: '10px',
        borderRadius: '4px',
        marginBottom: '10px'
    }
};

export default DatabaseManager;